import { createStore as reduxCreateStore } from "redux"

const reducer = (state, action) => {
    switch(action.type){
        case(`INCREMENT_VIEWS`): 
            return Object.assign({}, state, {
                numOfViews: state.numOfViews + 1,
            })
        case(`SET_VIEWS`):
            return Object.assign({}, state, {
                numOfViews: action.count,
            }) 
        case(`SET_CLAPS`):
            return Object.assign({}, state, {
                numOfClaps: action.count,
            })
        case(`SET_THEME`):
            return Object.assign({}, state, {
                theme: action.theme,
            })
        case(`SET_COLOR`):
            return Object.assign({}, state, {
                colorOfClap: action.color,
            })
            
    }
  return state
}

const initialState = {numOfViews: 0,
                      numOfClaps: 0,
                      theme: "dark",
                      colorOfClap: ""
                     }

const createStore = () => reduxCreateStore(reducer, initialState)
export default createStore